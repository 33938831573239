import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoyaltyContext } from "../../../../../provider/loyalty-provider";

export default function CnyBannerComponent(props) {
  const { vehicles } = useContext(LoyaltyContext);
  const history = useHistory();

  const onJoinContest = () => {
    if (vehicles === null || vehicles.length <= 0) {
        history.push("/loyalty/add-new-vehicle");
    } else {
      let top_height = 0;
      window.innerWidth < 1023 ? (top_height = 45) : (top_height = 85);
      window.scrollTo({
        top: props.serviceRef.current.offsetTop - top_height,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <section>
      <div className="container px-3 py-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <img
              src="/assets/images/cny-dashboard-banner-d.jpg"
              className="d-none d-md-block"
              alt="CNY Banner Desktop"
              width="100%"
              onClick={onJoinContest}
              style={{ cursor: "pointer" }}
            />
            <img
              src="/assets/images/cny-dashboard-banner-m.jpg"
              className="d-md-none"
              alt="CNY Banner Mobile"
              width="100%"
              onClick={onJoinContest}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}