import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { freeOilRangeCopy } from "../../../../../../helpers/oil-ranking";
import { AddServiceContext } from "../../../../../../provider/add-service-provider";
import { PopupContext } from "../../../../../../provider/popup-provider";
import { UserContext } from "../../../../../../provider/user-provider";
import { CheckServiceAPI } from "../../add-service-api";
import StepBarComponent from "../../step-bar-component/step-bar-component";
import "./service-description.scss";
import TagManager from "react-gtm-module";

export default function ServiceDescriptionComponent(props) {
	const { service } = useContext(AddServiceContext);
	const { popup, setPopup } = useContext(PopupContext);
	const { userProfile } = useContext(UserContext);
	const history = useHistory();
	const topSectionRef = useRef(null);

	var interval;

	useEffect(() => {
		onScrollTop();
		checkService();
		// Unmount
		return () => {
			clearInterval(interval);
		};
	}, []);

	const onScrollTop = () => {
		var headerOffset = 45;
		const elementPosition = topSectionRef.current.getBoundingClientRect().top;
		window.scrollTo({
			behavior: "smooth",
			top: elementPosition + window.pageYOffset - headerOffset,
		});
	};

	function checkService() {
		interval = setInterval(async function () {
			const res = await CheckServiceAPI(service.transaction_code);
			if (res.status_code == 200) {
				// popup
				const popupVal = Object.assign({}, popup);
				popupVal.open = true;
				popupVal.img = "success-icon";
				if (res.result != null) {
					TagManager.dataLayer({ dataLayer: { event: "Step 4 service Summary", cta_button: "add service verified" } });
					if (service.free_oil) {
						popupVal.content = [`You're all set! Your service for vehicle ${service.plate_number} has been approved.`];
					} else {
						popupVal.content = [`Thank you for your service! You are entitled to the following engine oil for free! ${freeOilRangeCopy([...service.current_oils, ...service.selection.current_oils])}.`];
					}
				} else {
					TagManager.dataLayer({ dataLayer: { event: "Step 4 service Summary", cta_button: "add service verified" } });
					popupVal.content = [`You're all set! Your service for vehicle ${service.plate_number} has been approved.`];
				}

				popupVal.buttons = [
					{
						type: "primary",
						text: "BACK TO DASHBOARD",
						tap: () => {
							history.push("/loyalty");
						},
					},
				];
				setPopup(popupVal);
				clearInterval(interval);
			} else if (res.status_code == 403) {
				// popup
				TagManager.dataLayer({ dataLayer: { event: "Step 4 service Summary", cta_button: "add service rejected" } });
				const popupVal = Object.assign({}, popup);
				popupVal.open = true;
				popupVal.img = "danger-icon";
				popupVal.content = ["Oh no! Your service submission was not approved. Please check your inbox for details."];

				popupVal.buttons = [
					{
						type: "primary",
						text: "GO TO INBOX",
						tap: () => {
							history.push("/inbox");
						},
					},
				];
				setPopup(popupVal);
				clearInterval(interval);
			}
		}, 3500);
	}

	return (
		<div ref={topSectionRef} className="service-description-component">
			<div className="container">
				<div className="row">
					<div className="offset-1 offset-md-2 col-10 col-md-8 mb-3 px-4 px-md-0">
						<h2 className="text-center">STEP 4</h2>
					</div>
					<div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0">
						<StepBarComponent active="4"></StepBarComponent>
					</div>
					<div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 px-4 px-md-0 text-center">
						<p className="mb-0">Verify your service with your workshop representative and don’t forget to join the <strong>TotalEnergies 2025 CNY Service & Cash8ack Campaign</strong> to stand a chance to win exciting prizes*!</p>
					</div>
					<div className="offset-md-2 col-12 col-md-8 mb-4 px-4 px-md-0">
						<img
						src="/assets/images/cny-contest-thumb-d.jpg"
						alt="Win exciting prizes in the Syiok! Loyalty Spend & Win Contest today"
						width="100%"
						className="d-none d-md-block"
						style={{borderRadius: "2rem"}}
						/>
						<img
						src="/assets/images/cny-contest-thumb-m.jpg"
						alt="Win exciting prizes in the Syiok! Loyalty Spend & Win Contest today"
						width="100%"
						className="d-md-none"
						style={{borderRadius: "1.5rem"}}
						/>
					</div>
					<div className="offset-md-2 col-12 col-md-8 mb-4 px-4 px-md-0">
						<button
						className="tom-button primary-button"
						onClick={() => {
							history.replace(`/2025-chinese-new-year-syiok-loyalty-campaign?transaction_id=${service.transaction_code}`);
						}}
						>
						Join contest
						</button>
					</div>
					<div className="offset-1 offset-md-2 col-10 col-md-8 px-4 px-md-0 text-center">
						<p>Here’s your service summary.</p>
					</div>
					<div className="offset-1 offset-md-4 col-10 col-md-4 mb-3 px-4 px-md-0">
						<div className="d-flex service-description">
							<p className="mb-0">Mobile Number:</p>
							<p className="mb-0">
								<b>{userProfile.mobile_number || ""}</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Vehicle:</p>
							<p className="mb-0">
								<b>{service.car_name}</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Mileage:</p>
							<p className="mb-0">
								<b>{(+service.current_mileage).toLocaleString()}km</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Service Date:</p>
							<p className="mb-0">
								<b>{service.service_date}</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Workshop ID:</p>
							<p className="mb-0">
								<b>{service.workshop_id.new_workshop_id != "" ? service.workshop_id.new_workshop_id : service.workshop_id.floating}</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Oil Variant:</p>
							<p className="mb-0">
								<b>{service.oil.float}</b>
							</p>
						</div>
						<div className="d-flex service-description">
							<p className="mb-0">Quantity:</p>
							<p className="mb-0">
								<b>
									{service.bottle_no} {service.bottle_no === 1 ? "bottle" : "bottles"}
								</b>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
