import axios from "axios";
import querystring from "querystring";
import { apiURL } from "../../../../config/config";
import Cookies from "js-cookie";

export const checkTransactionIdAPI = async (transaction_id) => {
  try {
    const response = await axios.get(`${apiURL}/api/campaign-cny/${transaction_id}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    });
    return response.data;
  } catch (error) {
    return {
      status_code: 502,
    };
  }
};

export const submitCampaignAPI = async (data, transaction_id) => {
  try {
    const response = await axios.post(
      `${apiURL}/api/campaign-cny/submit`,
      querystring.stringify({...data, pdpa: data.tnc, transaction_id}),
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      status_code: 502,
    };
  }
};