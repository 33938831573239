import React from "react";
import TopSectionScreen from "../../general-screen/top-section/top-section";
import CnyCampaignForm from "./cny-campaign-form/cny-campaign-form";
import "./cny-campaign.scss";

export default function CnyCampaignScreen(props) {
  return (
    <div className="cny-campaign-screen">
        <section className="top-section-component">
          <h1>Service & Cash8ack Campaign</h1>
        </section>
      <CnyCampaignForm></CnyCampaignForm>
    </div>
  );
}
